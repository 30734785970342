<template>
  <div>
    <!--begin::customer-->
    <div>

      <div class="card card-custom">
        <div class="card-body">
          <b-tabs content-class="mt-3" pills card>
            <b-tab v-for="(_data, index) in data" title-link-class="tab-custom-bg" :key="_data.tab_key">
              <template #title>
                <div class="tab-title">
                  <img :src="`/media/svg/tabs/${_data.icon}`" class="icon-tab" alt=""> <span class="pl-1 pr-1">{{_data.tab_name}}</span>
                </div>
              </template>
              <div class="row">
                <div v-for="(_li, _index_1) in _data.list" class="col-md-4 mt-3 mb-3" :key="_data.tab_key+_index_1">
                  <router-link :to="_li.link" target="_blank">
                    <b-card bg-variant="custom-light" body-class="p-5">
                      <h4>{{_li.name}}</h4>
                      <p>{{_li.description}}</p>
                    </b-card>
                  </router-link>
                </div>
              </div>

            </b-tab>

          </b-tabs>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Index",
  data() {
    return {
      mainRouteOfStructureView: 'base/dependency/reports_links',
      data: [],
    }
  },
  methods: {
    async getStructureView() {
      await ApiService.get(this.mainRouteOfStructureView).then((response) => {
      // let response = {
      //   data: {
      //     data: [
      //       {
      //         "name": "تقارير الفواتير 1",
      //         "tab_name": "المبيعات",
      //         "tab_key": "sales",
      //         "link": "/reports/sales-revenue-reports",
      //         "description": "توضح أداء المبيعات من حيث الإيرادات والكميات المباعة خلال فترة معينة، مما يساعد في تقييم الأداء واتخاذ القرارات الاستراتيجية.",
      //         "icon": 'trend',
      //       },
      //       {
      //         "name": "تقارير الفواتير 2",
      //         "tab_name": "المبيعات",
      //         "tab_key": "sales",
      //         "link": "/reports/sales-revenue-reports",
      //         "description": "توضح أداء المبيعات من حيث الإيرادات والكميات المباعة خلال فترة معينة، مما يساعد في تقييم الأداء واتخاذ القرارات الاستراتيجية.",
      //         "icon": 'trend',
      //       },
      //       {
      //         "name": "تقارير المشتريات",
      //         "tab_name": "المشتريات",
      //         "tab_key": "purchase",
      //         "link": "/reports/sales-revenue-reports",
      //         "description": "توضح أداء المبيعات من حيث الإيرادات والكميات المباعة خلال فترة معينة، مما يساعد في تقييم الأداء واتخاذ القرارات الاستراتيجية.",
      //         "icon": 'warehouse',
      //       },
      //       {
      //         "name": "تقارير المشتريات 2",
      //         "tab_name": "المشتريات",
      //         "tab_key": "purchase",
      //         "link": "/reports/purchase-reports",
      //         "description": "توضح أداء المبيعات من حيث الإيرادات والكميات المباعة خلال فترة معينة، مما يساعد في تقييم الأداء واتخاذ القرارات الاستراتيجية.",
      //         "icon": 'warehouse',
      //       },
      //     ]
      //   }
      // };
      this.data = [];
      let _tabs_keys = [];
      _tabs_keys = response.data.data.map((row) => {
        return row.tab_key;
      });
      _tabs_keys = [...new Set(_tabs_keys)];
      this.data = _tabs_keys.map((val) => {
        let _tab_name = response.data.data.find((row) => row.tab_key == val);
        let _list = response.data.data.filter((row) => row.tab_key == val).map((row) => {
          return row;
        });
        // let _final_list = {};
        // _list.forEach(function (obj) {
        //   _final_list[obj.key] = obj;
        // });
        return {
          tab_key: val,
          tab_name: _tab_name.tab_name,
          icon: _tab_name.icon,
          list: _list
        };
      })


      });
    },
  },
  mounted() {
    this.getStructureView();
  }
}
</script>

<style scoped>
.icon-tab{
  width: 20px;
  height: 20px;
}
.tab-title{
  font-size: 16px;
}
.tab-custom-bg{
  background-color: #e2e2e2 !important;
}
</style>